import { put, select, takeEvery } from 'typed-redux-saga';
import { endDownloadDataStatistics, GET_TASKS_STATISTICS, requestTasksStatistics, START_DOWNLOAD_DATA_STATISTICS } from './statisticsActions';
import { selectDataVisibleStatistics, selectTasksStatsByLocationIds, selectTasksStatsLoadingByLocationIds } from './statisticsSelectors';
import { requestTasksStatsByLocation } from 'api/task/statistics/statisticsActions';
import type { ItemsById } from 'model/entity';
import type { TasksStatistics } from 'api/task/statistics/statisticsModel';
import { takeLatest } from 'redux-saga/effects';
import i18n from 'i18n';
import { FeatureFlagState } from 'store/modules/featureFlags/model';
import { selectFeatureFlags } from 'store/modules/featureFlags/selectors';
import { FeatureFlags } from 'utils/featureFlags';
import { ColumnsCommonStatistics, ColumnsOverdueStatistics, ColumnsTasksExpiredStatistics, KeyValueColumns } from './statisticsModel';
import { Location } from "api/hierarchy/hierarchyModel";
import Papa from 'papaparse';

export function* getTasksStatisticsWatcherSaga() {
    yield takeEvery(
        GET_TASKS_STATISTICS,
        function* ({ payload: {locationId, endDate, startDate, tags} }: ReturnType<typeof requestTasksStatistics>) {
            const loadingById: ItemsById<boolean> = yield select(selectTasksStatsLoadingByLocationIds);
            const statsById: ItemsById<TasksStatistics> = yield select(selectTasksStatsByLocationIds);

            if (!loadingById[locationId] && !statsById[locationId]) {
                yield put(requestTasksStatsByLocation.init({
                    locationId,
                    endDate,
                    startDate,
                    tags,
                }));
            }
        },
    );
}

export function* downloadDataStattisticsWatcherSaga() {
    yield takeLatest(
        START_DOWNLOAD_DATA_STATISTICS,
        function* () {
            const dataVisible: Location[] = yield select(selectDataVisibleStatistics);
            const featureFlags: ItemsById<FeatureFlagState> = yield select(selectFeatureFlags);
            const statsById: ItemsById<TasksStatistics> = yield select(selectTasksStatsByLocationIds);
            const isTaksExpirableEnabled = featureFlags[FeatureFlags.TaksExpirable]?.enabled;
            const isOverdueTaskColumnsEnabled = featureFlags[FeatureFlags.OverdueTaskColumns]?.enabled;
            const commonColumns: string[] = Object.values(ColumnsCommonStatistics)
            
            if (isTaksExpirableEnabled) {
                commonColumns.push(ColumnsTasksExpiredStatistics.TasksExpired);
            }
            
            if (isOverdueTaskColumnsEnabled) {
                commonColumns.push(
                    ColumnsOverdueStatistics.Overdue,
                    ColumnsOverdueStatistics.SubmittedOverdue,
                    ColumnsOverdueStatistics.CompletedOverdue
                );
            }
            const columns: KeyValueColumns[] = commonColumns.map(col => ({key: col, value: i18n.t(col)}));

            const mapData = dataVisible.map((loc: Location) => {
                const rowData: { [key: string]: any } = {};

                columns.forEach(({key, value}) => {
                    switch (key) {
                        case ColumnsCommonStatistics.Location:
                            rowData[value] = loc.name;
                            break;
                        case ColumnsCommonStatistics.TaskCompletion:
                            rowData[value] = `${statsById[loc.id]?.completed} ${i18n.t('of')} ${statsById[loc.id]?.total}`;
                            break;
                        case ColumnsCommonStatistics['On-TimeCompletion']: {
                            const total = statsById[loc.id]?.total ?? 0;
                            const completed = statsById[loc.id]?.completed ?? 0;
                            const progress = Math.trunc((total === 0 || completed === 0) ? 0 : completed / total * 100);
                            rowData[value] = `${progress}%`;
                            break;
                        }
                        case ColumnsTasksExpiredStatistics.TasksExpired:
                            rowData[value] = loc.expired;
                            break;
                        case ColumnsOverdueStatistics.Overdue:
                            rowData[value] = loc.overdue;
                            break;
                        case ColumnsOverdueStatistics.CompletedOverdue:
                            rowData[value] = loc.completedOverdue;
                            break;
                        case ColumnsOverdueStatistics.SubmittedOverdue:
                            rowData[value] = loc.submittedOverdue;
                            break;
                        default:
                            break;
                    }
                });
                return rowData;
            });

            const csvData = Papa.unparse(mapData, {
                header: true
            });
            const blob = new Blob([csvData], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `tasks-by-location${new Date().toJSON()}.csv`;
            a.click();
            window.URL.revokeObjectURL(url);

            yield put(endDownloadDataStatistics());
        },
    );
}

export const statisticsSagas = [
    getTasksStatisticsWatcherSaga,
    downloadDataStattisticsWatcherSaga
];
