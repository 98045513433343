export enum ColumnsOverdueStatistics {
    'Overdue' = 'Overdue',
    'SubmittedOverdue' = 'Submitted Overdue' ,
    'CompletedOverdue' = 'Completed Overdue'
}

export enum ColumnsTasksExpiredStatistics {
    'TasksExpired' = 'Tasks Expired'
}

export enum ColumnsCommonStatistics {
    'Location' = 'Location',
    'TaskCompletion' = 'Task Completion',
    'On-TimeCompletion' = 'On-Time Completion',
}

export type KeyValueColumns = {
    key: string;
    value: string;
}
